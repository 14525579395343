import axios from "axios";

export async function fetchClientSecretToken() {
  return await axios
    .get("/create-client-secret")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getSubscriptionPlan(funnel_type) {
  return await axios
    .get(
      `/subscription/plans${funnel_type ? `?funnel_type=${funnel_type}` : ""}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function resumeSubscription() {
  return await axios
    .post("/subscription/resume")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function cancelSubscription() {
  return await axios
    .post("/subscription/cancel")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function changePlan(payload = { plan_id: null }) {
  return axios
    .post("/subscription/change-plan", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updatePaymentCard(payload = { payment_method_id: null }) {
  return axios
    .post("/update-payment-card", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function resubscribe(payload = { plan_id: null }) {
  return axios
    .post("/subscription/re-subscribe", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
