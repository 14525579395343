import {GET_ADVISOR, GET_CURRENT_USER, UPDATE_CURRENT_USER, UPDATE_ADVISOR_SETTING, UPDATE_NOTIFICATION, UPDATE_SUBSCRIPTION, UPDATE_CARD} from "../types";
import {cloneDeep} from "lodash";
import {mapObject} from "../../utils/generalHelper";

const initialState = {
  uuid: null,
  name: null,
  email: null,
  instagram: null,
  affiliate_code: null,
  funnel_type: null,
  phone: null,
  phone_number: null,
  country_code: null,
  country_iso_name: null,
  avatar: null,
  scheduling_link: null,
  facebook_link: null,
  advisor_message: null,
  address: {
    uuid: null,
    city: null,
    state: null,
    zipcode: null,
    address: null,
  },
  advisor: {
    uuid: null,
    name: null,
    email: null,
    instagram: null,
    affiliate_code: null,
    phone: null,
    avatar: null,
    scheduling_link: null,
    facebook_link: null,
    advisor_message: null,
  },
  notifications: {
    lead_email: false,
    lead_sms: false,
    mem_email: false,
    mem_sms: false,
    event_email: false,
    event_sms: false,
  },
  card: {
    type: null,
    last_four: null,
  },
  paypal_account: null,
  is_onboarding_completed: false,
  active_subscription: null,
  data_loaded: false,
  has_active_subscription: null,
  quick_links: null,
};

const authUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      const stateClone = cloneDeep(state);
      mapObject(stateClone, action.payload.user);

      return {
        ...stateClone,
      };

    case UPDATE_ADVISOR_SETTING:
      const advisorSettingClone = cloneDeep(state);
      mapObject(advisorSettingClone, action.payload.advisorSettings);

      return {
        ...advisorSettingClone,
      };

    case UPDATE_SUBSCRIPTION:
      const activeSubscriptionClone = cloneDeep(state);
      mapObject(activeSubscriptionClone, action.payload.activeSubscription);

      return {
        ...activeSubscriptionClone,
      };

    case UPDATE_CARD:
      const cardClone = cloneDeep(state);
      mapObject(cardClone.card, action.payload.cardDetails);

      return {
        ...cardClone,
      };

    case UPDATE_NOTIFICATION:
      const notificationsClone = cloneDeep(state);
      mapObject(notificationsClone.notifications, action.payload.notificationSettings);

      return {
        ...notificationsClone,
      };

    case GET_CURRENT_USER:
      return state;

    case GET_ADVISOR:
      return state.advisor;

    default:
      return state;
  }
};

export default authUserReducer;
