export const UPDATE_ONBOARDING_STEPS = "UPDATE_ONBOARDING_STEPS";

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const GET_CURRENT_USER = "UPDATE_CURRENT_USER";
export const GET_ADVISOR = "GET_ADVISOR";
export const UPDATE_ADVISOR_SETTING = "UPDATE_ADVISOR_SETTING";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_CARD = "UPDATE_CARD";

export const UPDATE_PROMOTE_SETTING = "UPDATE_PROMOTE_SETTING";
export const UPDATE_FUNNEL = "UPDATE_FUNNEL";

export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

export const UPDATE_ONLINE_USERS = "UPDATE_ONLINE_USERS";
export const REMOVE_OFFLINE_USER = "REMOVE_OFFLINE_USER";
export const ADD_ONLINE_USER = "ADD_ONLINE_USER";