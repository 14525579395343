import {UPDATE_ONBOARDING_STEPS} from '../types';

export const updateOnboardingSteps = (stepsState) => {
  return {
    type: UPDATE_ONBOARDING_STEPS,
    payload: {
      stepsState
    },
  }
}

