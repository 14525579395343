import axios from "axios";

export async function fetchQuestions(user_id = null, question = false) {
  let url = "/onboarding/advisor-questions";

  if (question) {
    url += "?question";
  }

  // Build the request
  const config = {
    params: {
      user: user_id,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function storeAnswer(
  payload = { uuid: null, answer: null, video_watched: false }
) {
  return await axios
    .post("/onboarding/answer", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
export async function markStepStatus(
  payload = { step_alias: null, status: false }
) {
  return await axios
    .post("/onboarding/mark-step-status", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function meetingScheduleEmbed(
  tempToken,
  payload = { step_alias: null, status: false }
) {
  const config = {
    headers: {
      Authorization: `Bearer ${tempToken}`,
    },
  };

  return await axios
    .post("/onboarding/mark-step-status", payload, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchAdvisorQuestionnaire() {
  return await axios
    .get("/onboarding/advisor-questions")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteQuestion(questionUid) {
  return await axios
    .delete(`/onboarding/delete-question/${questionUid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createQuestion(questionText) {
  try {
    const response = await axios.post("/onboarding/create-questions", {
      text: questionText,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function editQuestionnaire(uuid, data) {
  try {
    const response = await axios.post(
      `/onboarding/edit-question/${uuid}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
