import {UPDATE_FUNNEL, UPDATE_PROMOTE_SETTING} from "../types";

export const updatePromoteSettings = (promoteSettings) => {
  return {
    type: UPDATE_PROMOTE_SETTING,
    payload: {
      promoteSettings
    },
  }
}

export const updateFunnel = (funnel) => {
  return {
    type: UPDATE_FUNNEL,
    payload: {
      funnel
    },
  }
}