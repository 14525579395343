import "../assets/css/error404-error500.css?v1.1";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { errorPageImages } from "../utils/portalImages";
import { useNavigate } from "react-router-dom";

const { error_500 } = errorPageImages;

const Error500 = () => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSrc(error_500)
    img.src = error_500;
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className="server-error-div">
        <img className="server-error-img" src={imageSrc} alt="server error" />
        <h1 className="server-error-h1">Oops!</h1>
        <p className="server-error-p">Uh-oh! Our servers seem to be taking a break. Please bear with us while we sort things out. Feel free to try again later and we'll be back in full swing!</p>
        <Link onClick={() => navigate(-1)} className="server-error-button-home">
          Go Back
        </Link>
      </div>
    </>
  );
};

export default Error500;