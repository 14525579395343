import React from "react";

const PaginationButtons = ({ paginationData, onPageChange }) => {
    const { links } = paginationData;

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination">
                {links.map((link, index) => (
                    <li key={index} className={`page-item ${link.active ? 'active' : ''}`}>
                        <button
                            className="page-link"
                            disabled={!link.url}
                            onClick={() => onPageChange(link.url)}
                        >
                            <span dangerouslySetInnerHTML={{ __html: link.label }} />
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default PaginationButtons;