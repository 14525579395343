import axios from "axios";

export async function generatLead(payload = {name: null, email: null, instagram: null, affiliate_code: false}) {
  return await axios.post('/leads', payload)
    .then(response => {
      return response.data

    }).catch(error => {
      throw error.response.data
    })
}

export async function fetchReferral(referralId = null) {
  return await axios.get('/referral', {
    params: {
      referral: referralId
    }
  })
    .then(response => {
      return response.data

    }).catch(error => {
      throw error.response.data
    })
}