import axios from "axios";

const SIGNUp_PAYLOAD = {
  name: null,
  email: null,
  instagram: null,
  phone: null,
  phone_number: null,
  country_code: null,
  country_iso_name: null,
  password: null,
  password_confirmation: null,
};

export async function signUp(payload = SIGNUp_PAYLOAD) {
  return await axios
    .post("/register", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function signIn(
  payload = { email: null, password: null, remember: false }
) {
  return await axios
    .post("/login", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function forgotPassword(email) {
  return await axios
    .post("/forgot-password", {
      email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function resetPassword({
  email = null,
  token = null,
  password = null,
  password_confirmation = null,
}) {
  return await axios
    .post("/reset-password", {
      email: email,
      token: token,
      password: password,
      password_confirmation: password_confirmation,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function me() {
  return await axios
    .get("/me")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function logout() {
  return await axios
    .post("/logout")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getRoles() {
  return await axios
    .get("/roles")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function shortTermAuth(userId) {
  return await axios
    .post("/short-term-auth", {
      user_uuid: userId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
