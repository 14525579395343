let globalImages = {
  sidebar_logo: require("../assets/images/r2f_logo.png"),
  advisor_bg: require("../assets/images/advisor_bg.png"),
};

let dashboard = {
  piggy_bank: require("../assets/images/piggy-bank.png"),
  check_icon: require("../assets/images/Vector-2.svg").default,
};

let funnelImages = {
  funnel_optin: require("../assets/images/funnel-optin.png"),
  funnel_checkout: require("../assets/images/funnel-checkout.png"),
  funnel_webinar: require("../assets/images/funnel-webinar.png"),
  funnel_thankyou: require("../assets/images/funnel-thankyou.png"),
};

let coursePageImages = {
  lock_icon: require("../assets/images/Group-40.jpg"),
  course_icon: require("../assets/images/Group-704.svg").default,
};

let errorPageImages = {
  error_404: require("../assets/images/not-found.svg").default,
  error_500: require("../assets/images/server-error.png"),
  error_403: require("../assets/images/error-403.png"),
  network_error: require("../assets/images/no-internet-error.png"),
};

let promoteImages = {
  intro_background: require("../assets/images/theme/02_RTF_Bundle.png"),
};

let lessonImages = {
  arrow_down: require("../assets/images/arrow-down.png"),
};

let accountImages = {
  pingbank: require("../assets/images/piggy-bank.png"),
};

let adminCourseImages = {
  sortMenuDark: require("../assets/images/sort-menu-dark.png"),
  sortMenuLight: require("../assets/images/sort-menu-light.png"),
};

let challenges = {
  paperClip: require("../assets/images/icons/common/paperclip.svg").default,
  download: require("../assets/images/icons/common/download.svg").default,
  pause: require("../assets/images/icons/common/pause.svg").default,
  play: require("../assets/images/icons/common/play.svg").default,
  goldMedal: require("../assets/images/icons/common/gold-medal.svg").default,
  silverMedal: require("../assets/images/icons/common/silver-medal.svg").default,
  bronzeMedal: require("../assets/images/icons/common/bronze-medal.svg").default,
  crown: require("../assets/images/icons/common/crown.svg").default,
  crownGray: require("../assets/images/icons/common/crown-gray.svg").default,
};

export {
  globalImages,
  dashboard,
  funnelImages,
  coursePageImages,
  errorPageImages,
  promoteImages,
  lessonImages,
  accountImages,
  adminCourseImages,
  challenges,
};
