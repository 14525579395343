import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../redux/selectors";
import { LEVEL_ADMIN } from "../../utils/userPermissions";
import { usePermissions } from "../../context/permissionsContext";
import { fetchAllNotifications } from "../../api/NotificationsApi";

function Nav(props) {
  const currentUser = useSelector((state) => getCurrentUser(state));
  const { hasPermission } = usePermissions();
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetchAllNotifications();
        if (response.status) {
          const unread = response.data.filter(
            (notification) => !notification.read_at
          );
          setUnreadNotifications(unread);
        } else {
          console.error("Error fetching notifications:", response.message);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [currentUser.uuid]);

  return (
    <>
      <nav className="navbar navbar-top navbar-expand navbar-light bg-primary border-bottom">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav align-items-center ml-md-auto">
              <li className="nav-item d-xl-none">
                <div
                  className="pr-3 sidenav-toggler sidenav-toggler-dark"
                  data-action="sidenav-pin"
                  data-target="#sidenav-main"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav align-items-center ml-auto ml-md-0">
              <li className="nav-item mb-0 text-sm">
                Questions? Email:
                <a
                  href="mailto:support@racetofreedom.com"
                  style={{ color: "#525f7f" }}
                >
                  support@racetofreedom.com
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link pr-0"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div
                    className="media align-items-center"
                    style={{ position: "relative", padding: "5px" }}
                  >
                    <div className="media-body mr-3 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {currentUser.name}
                      </span>
                    </div>
                    <span className="avatar avatar-sm rounded-circle bell-icon">
                      <img
                        src={currentUser.avatar}
                        style={{
                          objectFit: "cover",
                          aspectRatio: "1",
                          position: "relative",
                        }}
                      />

                      {!hasPermission(LEVEL_ADMIN) &&
                        unreadNotifications.length > 0 && (
                          <span className="notification-badge-outer">
                            <i className="fa fa-bell"></i>
                          </span>
                        )}
                    </span>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-header noti-title">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </div>
                  <Link to="/account" className="dropdown-item">
                    <i className="ni ni-single-02"></i>
                    <span>My profile</span>
                  </Link>
                  {!hasPermission(LEVEL_ADMIN) && (
                    <>
                      <Link to="/account/payment" className="dropdown-item">
                        <i className="ni ni-calendar-grid-58"></i>
                        <span>Payment</span>
                      </Link>
                      <Link to="/notifications" className="dropdown-item">
                        <i className="fa fa-bell-o"></i>
                        <span>Notifications</span>
                      </Link>
                    </>
                  )}
                  <Link to="/support" className="dropdown-item">
                    <i className="ni ni-support-16"></i>
                    <span>Support</span>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link
                    onClick={props.handleLogout}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ni ni-user-run"></i>
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
