import {UPDATE_PERMISSIONS} from '../types';

const initialState = {
  userPermissions: [],
  };
  
  const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_PERMISSIONS:
        return {
          ...state,
          userPermissions: action.payload.permissions,
        };
      default:
        return state;
    }
  };
  
  export default permissionsReducer;
  