import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "./partials/Sidebar";
import Nav from "./partials/Nav";
import Header from "./partials/Header";
import PortalFooter from "./partials/PortalFooter";
import GetApp from "./partials/GetApp";
import PageLoader from "../modules/shared/components/PageLoader";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {me, logout} from "../api/AuthApi";
import {updateCurrentUser, updateFunnel, updateOnboardingSteps, updatePromoteSettings, updatePermissions} from "../redux/actionCreators";
import {useSignOut} from "react-auth-kit";
import {toastrOnTopCenter} from "../utils/toastr";
import Cookies from "js-cookie";
import NetworkError from "../pages/NetworkError";
import {PermissionsContext} from "../context/permissionsContext";
import {useLocation} from "react-router-dom";
import {getDocumentTitle} from "../utils/generalHelper";

/*
import {SocketContext} from "../context/socketContext";
import {useSocket} from "../services/pusherSocket/socketHook";
import {CHAT_MESSAGE_SENT} from "../services/pusherSocket/socketEventTypes";
*/

function authenticatedLayout(WrappedComponent) {
  return function AuthenticatedLayout(props) {
    const location = useLocation();

    const userPermissions = useSelector((state) => state.permissions.userPermissions);
    const dispatch = useDispatch();
    const signOut = useSignOut();
    const [isLoading, setIsLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const currentLayout = Cookies.get("currentLayout");
    const [cssLoaded, setCssLoaded] = useState(false);
    const linkExists = document.querySelector("link[href='/assets/css/authenticated-layout.css']");

    /*
    const [latestMessage, setLatestMessage] = useState({});

   useSocket({
      type: CHAT_MESSAGE_SENT,
      callBack: (payload) => receivedChatMessage(payload),
    }); */

    useEffect(() => {
      document.title = `R2F - ${getDocumentTitle(location)}`;

      if (currentLayout !== "authenticated" || !linkExists) {
        const links = document.querySelectorAll("link");
        for (let link of links) {
          const href = link.getAttribute("href");
          if (href === "/assets/css/guest-layout.css") {
            link.remove();
          } else if (href === "/assets/css/auth-layout.css") {
            link.remove();
          }
        }

        if (!linkExists) {
          const link = document.createElement("link");
          link.setAttribute("rel", "stylesheet");
          link.setAttribute("type", "text/css");
          link.setAttribute("href", "/assets/css/authenticated-layout.css");
          document.head.appendChild(link);
          link.onload = () => {
            Cookies.set("currentLayout", "authenticated", {expires: 7, path: ""});
            setCssLoaded(true);
          };
        }
      }
    }, []);

    useEffect(() => {
      refreshCurrenUserInfo();
      document.body.classList.remove("bg-default");
      window.scrollTo(0, 0);

      const handleOnline = () => setIsOnline(true);
      const handleOffline = () => setIsOnline(false);

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);

    const refreshCurrenUserInfo = async () => {
      try {
        const response = await me();
        const authUser = response.data.user;

        let funnel = {
          guest_user_funnel: authUser.guest_user_funnel,
          master_class_funnel: authUser.master_class_funnel,
          live_opportunity_call_funnel: authUser.live_opportunity_call_funnel,
          exclusive_member: authUser.exclusive_member,
        };

        dispatch(updateCurrentUser(authUser));
        dispatch(updateOnboardingSteps(authUser.onboarding_steps_state));
        dispatch(updatePromoteSettings(authUser.promote_settings));
        dispatch(updateFunnel(funnel));
        dispatch(updatePermissions(authUser.permissions));

        setIsLoading(false);
      } catch (error) {
        throw new Error("Something went wrong at user data fetching.");
      }
    };

    const handleLogout = () => {
      logout()
        .then(() => {
          signOut();
          sessionStorage.removeItem("isGetAppClosed");
        })
        .catch((error) => {
          toastrOnTopCenter("Something went wrong, try later", "error");
        });
    };

    const hasPermission = (permission) => {
      return userPermissions.includes(permission);
    };

    /* const receivedChatMessage = (payload) => {
      setLatestMessage(payload.message);
    };
    */

    if (currentLayout !== "authenticated" || !linkExists) {
      return <PageLoader />;
    }
    return (
      <>
        {/* <SocketContext.Provider value={{latestMessage}}> */}
        <HelmetProvider>
          <Helmet>
            {/* <script src="https://cdn.usebootstrap.com/bootstrap/4.4.1/js/bootstrap.min.js?v1.0"></script> */}
	    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>
            <script src="/assets/js/argon.js?v1.0"></script>
          </Helmet>
        </HelmetProvider>

        <PermissionsContext.Provider value={{hasPermission}}>
          <Sidebar handleLogout={handleLogout} />
          <div className={"main-content main-authenticated-container"} id="panel">
            <Nav handleLogout={handleLogout} />
            <Header />
            {isOnline && (
              <div className={"container-fluid mt--6 main-authenticated-item"}>
                {isLoading ? (
                  <>
                    <div style={{height: "100vh"}} className="d-flex align-items-center">
                      <PageLoader />
                    </div>
                  </>
                ) : (
                  <WrappedComponent {...props} refreshCurrenUserInfo={refreshCurrenUserInfo} />
                )}
                <PortalFooter />
              </div>
            )}

            <NetworkError isOnline={isOnline} />
          </div>
        </PermissionsContext.Provider>
        <GetApp />
        {/* </SocketContext.Provider> */}
      </>
    );
  };
}

export default authenticatedLayout;
