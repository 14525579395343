function PortalFooter() {
  let newStyle = {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "12px",
    marginRight: "12px",
  };

  return (
    <footer className="footer pt-0">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-5">
          <div className="copyright text-center  text-lg-left  text-muted">
            &copy; 2020{" "}
            <a
              href="https://my.racetofreedom.com"
              style={newStyle}
              className="font-weight-bold ml-1"
              target="_blank"
            >
              Race To Freedom
            </a>
          </div>
        </div>
        <div className="col-lg-6">
          <ul className="nav nav-footer justify-content-center justify-content-lg-end">
            <li className="nav-item">
              <a
                className="nav-link"
                style={newStyle}
                href="https://www.termsfeed.com/live/12dcf8c9-be89-44fe-8a61-9dc239065257"
                target="_blank"
              >
                Terms of Use
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                style={newStyle}
                href="https://www.termsfeed.com/live/eb251f1e-2c6d-4a08-b360-84ea30d9f72d"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                style={newStyle}
                href="https://www.termsfeed.com/live/3e93596e-9d62-45db-b660-dc78977e4a41"
                target="_blank"
              >
                Refund Policy
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                style={newStyle}
                href="https://www.termsfeed.com/live/0cc10d2f-6146-4551-82d5-fb039d17d84b"
                target="_blank"
              >
                Earning Disclaimer
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default PortalFooter;
