import React from "react";
import {useRouteError} from "react-router-dom";
import Error404 from "../../../pages/Error404";
import Error403 from "../../../pages/Error403";
import Error500 from "../../../pages/Error500";

const ErrorHandler = () => {
  const error = useRouteError();

  switch (error.status) {
    case 404:
      return <Error404 />;

    case 403:
      return <Error403 />;

    case 500:
      return <Error500 />;

    default:
      return <Error500 />;
  }
};

export default ErrorHandler;
