import {createBrowserRouter, RouterProvider} from "react-router-dom";
import axiosConfiguration from "./axiosConfig";
import {Suspense} from "react";
import {routes} from "./routes/web";

import {AuthProvider} from "react-auth-kit";
import PageLoader from "./modules/shared/components/PageLoader";
import ReactGA from "react-ga4";

ReactGA.send({hitType: "pageview", page: window.location.pathname});
axiosConfiguration();

let routers = [...routes];

const router = createBrowserRouter(routers);
export default function App() {

  return (
    <AuthProvider authType={"cookie"} authName={process.env.REACT_APP_NAME} cookieDomain={window.location.hostname} cookieSecure={window.location.protocol === "https:"}>
      <Suspense fallback={<PageLoader />}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthProvider>
  );
}
