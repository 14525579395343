import {UPDATE_ONBOARDING_STEPS} from '../types';

const initialState = {
  welcome_video_watched: false,
  joined_facebook_group: false,
  questionnaire_completed: false,
  meeting_scheduled: false,
}

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ONBOARDING_STEPS:
      return {
        ...state,
        ...action.payload.stepsState,
      }

    default:
      return state
  }
}

export default onboardingReducer;