import axios from "axios";

export async function createUserNotifications() {
  return await axios
    .post("/admin-notifications/create")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchAdminNotifications() {
  return await axios
    .get("/admin-notifications/filtered")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchAllNotifications() {
  return await axios
    .get("/notifications/")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function fetchUserNotifications(uuid) {
  return await axios
    .get(`/notifications/${uuid}/single`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}

export async function updateNotificationReadStatus(uuid) {
  return await axios
    .post(`/notifications/${uuid}/update-read-status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response;
    });
}