import AuthLayout from "../../layouts/AuthLayout";

function Login() {
  document.body.classList.add(
    "bg-default",
    "g-sidenav-show",
    "g-sidenav-pinned"
  );

  return (
    <>
      <div className="header bg-translucent-default pt-5 pb-7 py-lg-8">
        <div className="container">
          <div className="header-body text-center mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 px-5 text-center">
                <img
                  src="https://i.imgur.com/4h0317W.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 100"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <div className="container mt--8 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-4">
                <div className="text-center text-block-10 mb-4">
                  <b>Request Declined!</b>
                </div>
                <div className="text-center text-muted mb-4">
                  Thank you for your interest in joining Race to Freedom. After
                  careful consideration, we regret to inform you that your
                  request to join has not been approved by the admin at this
                  time. <br /> While you may not have access to our free
                  courses, we encourage you to stay connected with our community
                  through our social media channels.
                </div>
                <div className="block-body">
                  <div className="text-center">
                    <a
                      className="btn btn-primary my-4"
                      href="mailto:support@racetofreedom.com"
                    >
                      Contact Support
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthLayout(Login);
