import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { usePermissions } from "../../context/permissionsContext";
import { LEVEL_ADMIN, LEVEL_GUEST } from "../../utils/userPermissions";

const routes = [
  {
    path: "/",
    breadcrumb: null,
  },
  {
    path: "/dashboard",
    breadcrumb: "Home",
  },
  {
    path: "/answer",
    breadcrumb: null,
  },
  {
    path: "/answer/:user_id",
    breadcrumb: "Answer",
  },
  {
    path: "/profile",
    breadcrumb: null,
  },
  {
    path: "/profile/:user_id",
    breadcrumb: "Profile",
  },
  {
    path: "/course-categories/:course_id",
    breadcrumb: null,
  },
  {
    path: "/course-categories/courses/:lesson_id",
    breadcrumb: null,
  },
  {
    path: "/admin/courses/:course_id",
    breadcrumb: null,
  },
  {
    path: "/user-notification/:uuid",
    breadcrumb: null,
  },
];

const Header = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  const location = useLocation();
  const { hasPermission } = usePermissions();

  return (
    <div className="header bg-primary pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4 justify-content-center">
            <div className="col-lg-6 col-6">
              <h2 className="h2 d-inline-block mb-0 authenticated-heading">
                {breadcrumbs[breadcrumbs.length - 1]?.breadcrumb}
              </h2>
              <nav
                aria-label="breadcrumb"
                className="d-none d-md-inline-block ml-md-4"
              >
                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fa fa-home" />
                      &nbsp;&nbsp;-&nbsp;&nbsp;Dashboard
                    </Link>
                  </li>
                  {breadcrumbs.map(({ match, breadcrumb }) => {
                    const uuidRegex =
                      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                    const pathParts = match.pathname.split("/");
                    if (pathParts.some((part) => uuidRegex.test(part))) {
                      return null;
                    }

                    if (match.pathname == "/admin") {
                      return (
                        <span
                          key={match.pathname}
                          className="breadcrumb-item text-default"
                        >
                          {breadcrumb}
                        </span>
                      );
                    }
                    return (
                      <Link
                        key={match.pathname}
                        to={match.pathname}
                        className={
                          match.pathname === location.pathname
                            ? "breadcrumb-item active"
                            : "breadcrumb-item custom-text-dark"
                        }
                      >
                        {breadcrumb}
                      </Link>
                    );
                  })}
                </ol>
              </nav>
            </div>
            <div className="col-lg-5 col-5 text-right"></div>
            <div className="col-lg-12 pl-lg-5">
              {(location.pathname === "/courses" ||
                location.pathname === "/dashboard") &&
                hasPermission(LEVEL_GUEST) &&
                !hasPermission(LEVEL_ADMIN) && (
                  <span className="breadcrumb breadcrumb-links breadcrumb-dark ml-lg-3">
                    To unlock all courses and maximize your learning potential,
                    <Link
                      to="/account/payment"
                      style={{ color: "#32325a", textDecoration: "underline" }}
                    >
                      select a suitable plan now
                    </Link>
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
