export const downloadCSV = (csvData, headers, filename) => {
  const csvContent = generateCSVContent(csvData, headers);

  const blob = new Blob([csvContent], {type: 'text/csv'});
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename || 'data.csv';
  link.click();
  URL.revokeObjectURL(url);
};

const generateCSVContent = (csvData, headers) => {
  let csvContent = headers.join(',') + '\n';
  csvData.forEach((row) => {
    const values = headers.map((header) => {
      if (header === 'First Name' || header === 'Last Name') {
        const [firstName, lastName] = getFirstNameAndLastName(row.name);
        return header === 'First Name' ? firstName : lastName;
      } else {
        return row[header.toLowerCase()] || '';
      }
    });
    const rowContent = values.join(',') + '\n';
    csvContent += rowContent;
  });
  return csvContent;
};

const getFirstNameAndLastName = (name) => {
  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');
  return [firstName, lastName];
};