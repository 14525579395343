import axios from "axios";

export async function getAllCourses(category_uuid, page) {
  // return axios.get(`/courses/categories/${category_uuid}`, {
  return axios
    .get(`/courses/all`, {
      params: {
        page: page,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchCoursesCategories() {
  return await axios
    .get("/courses/categories")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getCourseLessons(course_uuid) {
  return axios
    .get(`/courses/${course_uuid}/lessons`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function markLessonWatched(
  payload = { lesson_uuid: null, status: false }
) {
  return await axios
    .post("/courses/mark-lesson-status", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getRecentAndUpcomingLessons() {
  return axios
    .get(`/courses/recent-lessons`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function markLessonWatching(lesson_uuid = null) {
  const payload = lesson_uuid ? { lesson_uuid } : {};

  return axios
    .post(`/courses/mark-lesson-watching/${lesson_uuid}`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
