import React, {useEffect, useState} from "react";
import {errorPageImages} from "../utils/portalImages";

const {network_error} = errorPageImages;

const NetworkError = ({isOnline}) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSrc(network_error)
    img.src = network_error;

  }, []);

  return (
    <>
      {!isOnline && (
        <div className="network-error-div">
          <div className="d-flex justify-content-center">
            <img className="img-fluid" width="500px" src={imageSrc} alt="server error"/>
          </div>
          <h1 className="network-error-h1 text-center">Oops!</h1>
          <p className="network-error-p text-center">Uh-oh! Seems like your internet connection is down.</p>
        </div>
      )
      }
    </>
  )
};

export default React.memo(NetworkError);
