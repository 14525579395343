import * as React from 'react';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

const SidenavSkeleton = () => {

    return (
        <>
            <Grid container spacing={8}>
                <Grid item xs>
                    <Typography component="div" variant="h3" >
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                    </Typography>
                </Grid>
            </Grid>

            <hr className="my-3 bg-gray" />

            <Grid container spacing={8} >
                <Grid item xs>
                    <Typography component="div" variant="h3">
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                        <Skeleton animation="wave" sx={{ bgcolor: '#172B4D' }} />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default SidenavSkeleton;