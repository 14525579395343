export const WELCOME_VIDEO_ALIAS = "welcome_video_watched";
export const JOIN_FB_GROUP_ALIAS = "joined_facebook_group";
export const QUESTIONNAIRE_COMPLETED_ALIAS = "questionnaire_completed";
export const MEETING_SCHEDULED_ALIAS = "meeting_scheduled";

export const FB_GROUP_URL = "https://www.facebook.com/groups/776610176229727";

export const WEBINAR_VIDEO = "webinar_video";
export const WELCOME_VIDEO = "welcome_video";
export const PROMOTE_VIDEO = "promote_video";

export const GUEST_USER_FUNNEL = "guest_user";
export const MASTER_FUNNEL = "master";
export const LIVE_OPT_CALL_FUNNEL = "live_opportunity_call";
export const EXCLUSIVE_MEMBER = "exclusive_member";

export const MAX_CALENDAR_EVENTS_PER_DAY = 7;

export const VIMEO = "vimeo";
export const WISTIA = "wistia";

export const MAX_PROFILE_AVATAR_SIZE = 5242880; //in bytes

export const ENAGIC = "Enagic";
export const TRIFECTA = "Trifecta";
export const CORE = "Core";
export const ADVISOR = "Advisor";
export const ACTIVE_RECRUITER = "Active-Recruiter";

export const GUEST_BADGE = "Guest";
export const ENAGIC_BADGE = "Enagic";
export const TRIFECTA_BADGE = "Trifecta";
export const ADVISOR_BADGE = "Advisor";
