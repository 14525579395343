export const COURSE = "course";
export const PROMOTE = "promote";
export const LEADERBOARD = "leaderboard";
export const WATCH_ANSWERS = "questionnaire.watch_answers";
export const UPDATE_ROLE = "member.update_role";
export const MEMBER_INFO_VIEW = "member.member_info.view";
export const AFFILIATE_INFO_VIEW = "member.affiliate_info.view";
export const ADMIN_DASHBOARD = "admin.dashboard";
export const ADMIN_COURSE = "admin.admin_course";
export const UPDATE_MEMBER_ADMINISTRATION = "admin.update_member_administration";
export const ADVISOR_SETTINGS = "settings.advisor_settings";
export const NOTIFICATIONS_LEAD = "settings.notifications.lead";
export const NOTIFICATIONS_MEMBER = "settings.notifications.member";
export const NOTIFICATIONS_CALENDAR_EVENT = "settings.notifications.calendar_event";
export const NOTES_CREATE = "notes.create";
export const NOTES_EDIT = "notes.edit";
export const CALENDAR_NOTIFICATIONS_VIEW = "calendar.notifications.view";
export const CALENDAR_NOTIFICATIONS_CREATE = "calendar.notifications.create";
export const CALENDAR_NOTIFICATIONS_EDIT = "calendar.notifications.edit";
export const CALENDAR_NOTIFICATIONS_DELETE = "calendar.notifications.delete";
export const CALENDAR_EVENTS_VIEW = "calendar.events.view";
export const CALENDAR_EVENTS_CREATE = "calendar.events.create";
export const CALENDAR_EVENTS_EDIT = "calendar.events.edit";
export const CALENDAR_EVENTS_DELETE = "calendar.events.delete";
export const BLOCKED_WORDS_OPERATIONS = "promote.blocked_words_operations";
export const LIVE_AT_OPERATIONS = "promote.live_at_operations";
export const DOWNLINES = "promote.downlines";
export const PRO_FACEBOOK_GROUP = "quick_links.pro_facebook_group";
export const R2F_MEMBERS = "quick_links.r2f_members";
export const R2F_TELEGRAM = "quick_links.r2f_telegram";
export const SOFTWARE_GUIDE = "quick_links.software_guide";
export const ADVISOR_TELEGRAM = "quick_links.advisor_telegram";
export const LEVEL_ADMIN = "access_level.admin";
export const LEVEL_ALL_MEMBER = "access_level.all_member";
export const LEVEL_GUEST = "access_level.guest";
export const LEVEL_ENAGIC = "access_level.enagic";
export const LEVEL_TRIFECTA = "access_level.trifecta";
export const LEVEL_ADVISOR = "access_level.advisor";
export const LEVEL_CORE = "access_level.core";
export const LEVEL_RECRUITER = "access_level.active_recruiter";
export const DOWNLOAD_CSV = "promote.download_csv";