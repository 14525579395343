import {GET_ADVISOR, GET_CURRENT_USER, UPDATE_CURRENT_USER, UPDATE_ADVISOR_SETTING, UPDATE_NOTIFICATION, UPDATE_SUBSCRIPTION, UPDATE_CARD} from "../types";

export const updateCurrentUser = (user) => {
  return {
    type: UPDATE_CURRENT_USER,
    payload: {
      user,
    },
  };
};

export const updateAdvisorSetting = (advisorSettings) => {
  return {
    type: UPDATE_ADVISOR_SETTING,
    payload: {
      advisorSettings,
    },
  };
};

export const updateSubscription = (activeSubscription) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {
      activeSubscription,
    },
  };
};

export const updateCard = (cardDetails) => {
  return {
    type: UPDATE_CARD,
    payload: {
      cardDetails,
    },
  };
};

export const updateNotification = (notificationSettings) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: {
      notificationSettings,
    },
  };
};

export const getCurrentUser = () => {
  return {
    type: GET_CURRENT_USER,
  };
};

export const getAdvisor = () => {
  return {
    type: GET_ADVISOR,
  };
};
