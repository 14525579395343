import "../assets/css/error404-error500.css?v1.1";
import React from "react";
import {Link} from "react-router-dom";
import {errorPageImages} from "../utils/portalImages";
import {useNavigate} from "react-router-dom";

const Error403 = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="error-403-div">
        <img className="error-403-img" src={errorPageImages.error_403} alt="error403" />
        <h1 className="error-403-h1">Unauthorized</h1>
        <p className="error-403-p">Unfortunately, you do not have permission to view this page.</p>
        <Link onClick={() => navigate(-1)} className="error-403-button-home">
          Go Back
        </Link>
      </div>
    </>
  );
};

export default Error403;
