import {UPDATE_ONLINE_USERS, REMOVE_OFFLINE_USER, ADD_ONLINE_USER} from "../types";
import {cloneDeep} from "lodash";

export const onlineUserDict = {
  uuid: null,
  name: null,
  email: null,
  avatar: null,
  joined_at: null,
}

const initialState = {
  users: [],
}


const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ONLINE_USERS:
      return {
        ...state,
        users: action.payload.users,
      }

    case REMOVE_OFFLINE_USER:
      const user = action.payload.user;
      const currentOnlineUsers = removeUser(user, state);

      return {
        ...state,
        users: currentOnlineUsers,
      }

    case ADD_ONLINE_USER:
      const newUser = action.payload.user;
      const newOnlineUsers = addUser(newUser, state);

      return {
        ...state,
        users: newOnlineUsers,
      }

    default:
      return state;
  }
}

function removeUser(user, state) {
  let onlineUsers = state.users;

  if (user) {
    onlineUsers = onlineUsers.filter(onlineUser => onlineUser.uuid != user.uuid);
  }

  return onlineUsers;
}

function addUser(user, state) {
  let onlineUsers = cloneDeep(state.users);
  const userExisted = onlineUsers.find(onlineUser => onlineUser.uuid == user.uuid);

  if (!userExisted) {
    onlineUsers.push(user);
  }

  return onlineUsers;
}

export default permissionsReducer;