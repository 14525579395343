import "../assets/css/error404-error500.css?v1.1";
import React from "react";
import {Link} from "react-router-dom";
import {errorPageImages} from "../utils/portalImages";
import {useNavigate} from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="not-found-div">
        <img className="not-found-img" src={errorPageImages.error_404} alt="not found" />
        <h1 className="not-found-h1">Something's wrong here.</h1>
        <p className="not-found-p">This is a 404 error, which means you've clicked on a bad link or entered an invalid URL. Use the button below to go back to the homepage.</p>
        <Link onClick={() => navigate(-1)} className="not-found-button-home">
          Go Back
        </Link>
      </div>
    </>
  );
};

export default Error404;
