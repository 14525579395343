import { UPDATE_PROMOTE_SETTING, UPDATE_FUNNEL } from "../types";
import { cloneDeep } from "lodash";
import { mapObject } from "../../utils/generalHelper";

const initialState = {
  promote_settings: {
    display_name: null,
    display_text: null,
    head_code: null,
    body_code: null,
    admin_settings: {},
  },
  guest_user_funnel: {
    welcome: null,
    webinar: null,
    checkout: null,
  },
  master_class_funnel: {
    welcome: null,
    webinar: null,
    checkout: null,
  },
  live_opportunity_call_funnel: {
    welcome: null,
    webinar: null,
    checkout: null,
  },
  exclusive_member: {
    checkout: null,
  },
};

const promoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROMOTE_SETTING:
      const stateClone = cloneDeep(state);
      mapObject(stateClone.promote_settings, action.payload.promoteSettings);

      return {
        ...stateClone,
      };

    case UPDATE_FUNNEL:
      let funnel = cloneDeep({
        guest_user_funnel: state.guest_user_funnel,
        master_class_funnel: state.master_class_funnel,
        live_opportunity_call_funnel: state.live_opportunity_call_funnel,
        exclusive_member: state.exclusive_member,
      });
      mapObject(funnel, action.payload.funnel);

      return {
        ...state,
        ...funnel,
      };

    default:
      return state;
  }
};

export default promoteReducer;
