import React, { useState, useEffect } from "react";
import "../../assets/css/get-app.css";
import { globalImages } from "../../utils/staticImages";

function GetApp() {
  const [isVisible, setIsVisible] = useState(true);
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    const isGetAppClosed = sessionStorage.getItem("isGetAppClosed");
    if (isGetAppClosed) {
      setIsVisible(false);
    }

    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod|mac/.test(userAgent)) {
      setIsAppleDevice(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    sessionStorage.setItem("isGetAppClosed", "true");
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="outer-container">
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-2">
            <img
              className="logo"
              src={globalImages.r2f_logo}
              loading="lazy"
              alt="r2f-logo"
            />
          </div>
          <div className="col-4 pl-2 text-left">
            <span>Get App</span>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            {isAppleDevice ? (
              <a
                href="https://apps.apple.com/in/app/racetofreedom/id6473527134"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="app-store-logo"
                  src={globalImages.app_store}
                  loading="lazy"
                  alt="r2f-app-store"
                />
              </a>
            ) : (
              <a
                href="https://play.google.com/store/apps/details?id=com.racetofreedom&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="play-store-logo"
                  src={globalImages.play_store}
                  loading="lazy"
                  alt="r2f-google-play-store"
                />
              </a>
            )}
          </div>
          <div className="col-2">
            <button
              type="button"
              className="header-close-btn"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true" className="close-btn-span">
                &times;
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetApp;
