import axios from "axios";

const CourseData = {
  name: null,
  description: null,
  thumbnail_uuid: null,
  allowed_audience_roles: null,
};

const updatePermissionData = {
  course_uuid: null,
  toggleable_audience_role: null,
};

const newSectionData = {
  course_uuid: null,
  name: null,
  description: null,
};

const editSectionData = {
  section_uuid: null,
  name: null,
  description: null,
};

const newLessonData = {
  section_uuid: null,
  name: null,
  description: null,
  resources: null,
  video_link: null,
  video_source: null,
};

const editLessonData = {
  lesson_uuid: null,
  name: null,
  description: null,
  resources: null,
  video_link: null,
  video_source: null,
};

const sortSectionData = {
  source_section: null,
  destination_section: null,
  action_type: null,
};

const sortLessonData = {
  source_lesson: null,
  destination_lesson: null,
  action_type: null,
};

const sortCoursesData = {
  source_lesson: null,
  destination_lesson: null,
  action_type: null,
}

export async function fetchAdminRoles() {
  return await axios
    .get("/admin-courses/roles")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchAdminCourses() {
  return await axios
    .get("/admin-courses/all")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchAdminCourse(courseUUID) {
  return await axios
    .get(`/admin-courses/${courseUUID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createCourse(payload = CourseData) {
  return await axios
    .post("/admin-courses", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function editCourse(courseUUID, payload = CourseData) {
  return await axios
    .post(`/admin-courses/${courseUUID}/edit`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteCourse(courseUUID) {
  return await axios
    .delete(`/admin-courses/${courseUUID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createSection(payload = newSectionData) {
  return await axios
    .post("/admin-courses/sections", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function editSection(payload = editSectionData) {
  return await axios
    .post("/admin-courses/sections/edit", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteSection(sectionUUID) {
  return await axios
    .delete(`/admin-courses/sections/${sectionUUID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createLesson(payload = newLessonData) {
  return await axios
    .post("/admin-courses/lessons", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function editLesson(payload = editLessonData) {
  return await axios
    .post("/admin-courses/lessons/edit", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteLesson(lessonUUID) {
  return await axios
    .delete(`/admin-courses/lessons/${lessonUUID}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateCoursePermission(payload = updatePermissionData) {
  return await axios
    .post("/admin-courses/update-permissions", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function sortSections(payload = sortSectionData) {
  return await axios
    .post("/admin-courses/sections/sort", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function sortLessons(payload = sortLessonData) {
  return await axios
    .post("/admin-courses/lessons/sort", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function sortCourses(payload = sortCoursesData) {
  return await axios
    .post("/admin-courses/sort", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}