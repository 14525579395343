import axios from "axios";

export async function updatePromoteSettings(formData) {
  return await axios.post('/promote/settings', formData)
    .then(response => {
      return response.data

    }).catch(error => {
      throw error.response.data
    });
}

export async function fetchPromoteStats(payload = { period: 'this_month' }) {
  return await axios.post('/promote/stats', payload)
    .then(response => {
      return response.data;

    }).catch(error => {
      throw error.response.data;
    });
}

export async function getLeadListing(payload = {
  user_uuid: null,
  paginated: true,
  downlines: false,
  funnel_type: null,
  period: 'this_month',
  query: null,
  admin_stats_filter: false,
}, URL = null) {
  const url = URL || `/leads/${payload.user_uuid}`;
  const params = {};

  if (!URL) {
    params.paginated = payload.paginated;
    params.downlines = payload.downlines;
    params.funnel_type = payload.funnel_type;
    params.period = payload.period;
    params.query = payload.query;
    params.admin_stats_filter = payload.admin_stats_filter;
  }

  return axios.get(url, {params})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response.data;
    });
}

export async function deleteLead(lead_id) {
  const url = `/leads/${lead_id}`;

  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export async function getMemberListing(payload = {
  user_uuid: null,
  paginated: true,
  downlines: false,
  funnel_type: null,
  period: 'this_month',
  query: null,
  filterable_role: null,
  admin_stats_filter: false,
}, URL = null) {
  const url = URL || `/members/${payload.user_uuid}`;
  const params = {};

  if (!URL) {
    params.paginated = payload.paginated;
    params.downlines = payload.downlines;
    params.funnel_type = payload.funnel_type;
    params.period = payload.period;
    params.query = payload.query;
    params.filterable_role = payload.filterable_role;
    params.admin_stats_filter = payload.admin_stats_filter;
  }

  return axios.get(url, {params})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response.data;
    });
}

export async function getExclusiveMemberListing(payload = {
  user_uuid: null,
  paginated: true,
  downlines: false,
  funnel_type: null,
  period: 'this_month',
  query: null,
  filterable_role: null,
  admin_stats_filter: false,
}, URL = null) {
  const url = URL || `/exclusive-members/${payload.user_uuid}`;
  const params = {};

  if (!URL) {
    params.paginated = payload.paginated;
    params.downlines = payload.downlines;
    params.funnel_type = payload.funnel_type;
    params.period = payload.period;
    params.query = payload.query;
    params.filterable_role = payload.filterable_role;
    params.admin_stats_filter = payload.admin_stats_filter;
  }

  return axios.get(url, {params})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response.data;
    });
}

export async function getGuestListing(payload = {
  user_uuid: null,
  paginated: true,
  downlines: false,
  funnel_type: null,
  period: 'this_month',
  query: null,
  filterable_role: null,
  admin_stats_filter: false,
}, URL = null) {
  const url = URL || `/guest/${payload.user_uuid}`;
  const params = {};

  if (!URL) {
    params.paginated = payload.paginated;
    params.downlines = payload.downlines;
    params.funnel_type = payload.funnel_type;
    params.period = payload.period;
    params.query = payload.query;
    params.filterable_role = payload.filterable_role;
    params.admin_stats_filter = payload.admin_stats_filter;
  }

  return axios.get(url, {params})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error.response.data;
    });
}