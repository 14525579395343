import {combineReducers} from '@reduxjs/toolkit'

import onboarding from "./onboardingReducer";
import authUser from "./authUserReducer";
import promote from "./promoteReducers";
import permissions from "./permissionsReducer";
import onlineUsers from "./onlineUsersReducer";

export default combineReducers({
  onboarding,
  authUser,
  promote,
  permissions,
  onlineUsers,
});
